import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "karyailmiah";
const modelPath = `/${modelName}/`;

class KaryaIlmiah extends ModelBase {
  constructor() {
    let model = {
      id: null,
      judul: "",
      tanggal: null,
      metode: { nama: "" },
      diagnosis: { grup: "", nama: "" },
      stase: {id: null, nama: ""},
      pembimbing_set: [],
      penguji_set: [],
      dokumenkaryailmiah_set: [],
      hasil: "",
      verifikasi: "",
      nilai: null,
      nilai_sikap: null,
      foto: null,
      extra_data: {}
    };
    super(modelName, model, [], ["hasil", "nilai", "nilai_sikap"], modelPath);
  }

  getLoadData(data) {
    let nilai = data['nilai'];
    let nilai_sikap = data['nilai_sikap'];
    data['nilai'] = nilai? parseFloat(nilai):nilai;
    data['nilai_sikap'] = nilai_sikap? parseFloat(nilai_sikap):nilai_sikap;
    return JSON.parse(JSON.stringify(data));
  }

  getPayload() {
    let karyailmiah = this.observables.karyailmiah;
    let ver = karyailmiah.nilai != null && karyailmiah.hasil != "Belum" ? "Ya" : "Belum";
    this.observables.karyailmiah.verifikasi = ver;
    let data = { nilai_sikap: karyailmiah.nilai_sikap, nilai: karyailmiah.nilai, hasil: karyailmiah.hasil, verifikasi: ver };
    return data;
  }
}

export default KaryaIlmiah;
